// Modules
import React, { useEffect, useState, Suspense, useCallback } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Helmet } from "react-helmet";

//redux actions and selectors
import {
  selectCurrentUser,
  selectStudentMode,
  selectTutorFeedbackDashboard,
  selectTutorSchedule,
} from "../../redux/user/user.selectors";
import {
  selectCurrentLesson,
  selectDefaultTrackAndCheckpoint,
  selectLessonsLoading,
  selectThisWeeksLessonPresent,
  selectTrackCheckpointSelection,
} from "../../redux/lessons/lessons.selectors";
import {
  selectCurrentGroup,
  selectGroupsIdNameMap,
} from "../../redux/groups/groups.selectors";
import { setTrackCheckpointSelection } from "../../redux/lessons/lessons.actions";
import {
  fetchLessonsAndGroupsAsyncStart,
  fetchUserLessonDataAsyncStart,
} from "../../redux/user/user.actions";
import { selectNews, selectTutorInfo } from "../../redux/news/news.selectors";

// Utilities
import { validateLessonTime } from "../../utils/validation.utils";
import { setCurrentChallengeStatus } from "../../utils/firebase/realtime-db-utils";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";

// Components
import HeaderLinks from "../../material-kit-components/Header/HeaderLinks";
import Header from "../../material-kit-components/Header/Header";
import PreviousLessonsCard from "./sections/PreviousLessonsCard.component";
import GridContainer from "../../material-kit-components/Grid/GridContainer";
import GridItem from "../../material-kit-components/Grid/GridItem";
import ChallengeDataRenderer from "../../components/ChallengeDataRenderer/ChallengeDataRenderer.component.jsx";
import MediaModal from "../../components/MediaModal/MediaModal.component";
import CurrentLessonCard from "./sections/CurrentLessonCard.component";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen.component";
import ErrorCard from "../../components/ErrorCard/ErrorCard.componet";
import NewsFeedCard from "../../components/NewsFeedCard/NewsFeedCard.component";

// Custom Hook
import useAllLoading from "../../customHooks/useAllLoading.hook";
import useAllError from "../../customHooks/useAllError.hook";

// Assets
import popoverStyles from "../../assets/jss/material-kit-react/popoverStyles.js";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle.js";
import { dashboardStyles } from "./Dashboard.style";
import { Box } from "@material-ui/core";
import { getThisWeeksUnsubmittedFeedback } from "../../utils/firebase/firestore-utils";
import DashboardNameDisplay from "./sections/DashboardNameDisplay.component";
import TutorInfoCard from "components/TutorInfoCard/TutorInfoCard.component";

// Code split tutor only functionality
const SubHeaderLinks = React.lazy(() =>
  import("../../material-kit-components/Header/SubHeaderLinks")
);
const TutorDashboardInfoComponent = React.lazy(() =>
  import("../../components/TutorDashboardInfo/TutorDashboardInfo.component")
);

const Alerts = React.lazy(() =>
  import("../../components/Alerts/Alerts.components")
);
const UncompletedFeedbackAlertMessage = React.lazy(() =>
  import("../../components/Alerts/UncompletedFeedbackAlertMessage.component")
);

const styles = {
  ...popoverStyles,
  ...modalStyle,
};

const useStyles = makeStyles(styles);
const useDashboardStyles = makeStyles(dashboardStyles);

const Dashboard = ({
  currentUser,
  currentGroup,
  trackCheckpointSelection,
  setTrackCheckpointSelection,
  defaultTrackAndCheckpoint,
  lessonsLoading,
  thisWeeksLessonPresent,
  currentLesson,
  fetchUserLessonDataAsyncStart,
  fetchLessonsAndGroupsAsyncStart,
  groupsIdNameMap,
  studentMode,
  tutorFeedbackDashboard,
  tutorSchedule,
  currentNews,
  currentTutorInfo,
}) => {
  const [livestream, setLivestream] = useState(null);
  const [userLessonTimeValidation, setUserLessonTimeValidation] =
    useState(false);
  const [alerts, setAlerts] = useState([]);

  const allLoading = useAllLoading();
  const anyError = useAllError();

  const classes = useStyles();
  const dashboardClasses = useDashboardStyles();

  //Used for revalidating this weeks lesson if student is sitting and waiting for it to appear
  let thisWeeksLessonValidationInterval;

  // Load up data regarding lesson progress for the current user
  useEffect(() => {
    if (currentUser) {
      fetchUserLessonDataAsyncStart(currentUser);
    }
  }, [currentUser]);

  // For admin users, load up alerts for unsubmitted feedback that has been initialized in the current week.
  useEffect(() => {
    if (currentUser?.admin) {
      getThisWeeksUnsubmittedFeedback(groupsIdNameMap)
        .then((res) => {
          const messages = res.map((feedbackDoc, i) => (
            <Suspense key={i} fallback={<div></div>}>
              <UncompletedFeedbackAlertMessage {...{ feedbackDoc }} />
            </Suspense>
          ));
          setAlerts(messages);
        })
        .catch(console.error);
    }
  }, [currentUser, groupsIdNameMap, tutorFeedbackDashboard, tutorSchedule]);

  // Tries to validate that this weeks lesson is available.
  // If not, it attemts to validate again in 10seconds
  useEffect(() => {
    //Clear existing interval when currentLesson or availability changes
    if (thisWeeksLessonValidationInterval) {
      clearInterval(thisWeeksLessonValidationInterval);
    }

    /*
    If lessons are loaded, this weeks lesson(s) exist and are not present
    in previous lessons, we run a validation check and either return true or 
    keep running validation at the given interval until it is true
    */
    if (!lessonsLoading) {
      if (thisWeeksLessonPresent) {
        const validation = validateLessonTime(currentUser);
        setUserLessonTimeValidation(validation);
        if (!validation) {
          thisWeeksLessonValidationInterval = setInterval(() => {
            const validation = validateLessonTime(currentUser);
            setUserLessonTimeValidation(validation);
          }, 10000);
        }
      } else {
        console.log("Passes Validation: No 'This Week's Lesson'");
        setUserLessonTimeValidation(true);
      }
    }
    //Clear interval on unmount if it exists
    return () => {
      if (thisWeeksLessonValidationInterval) {
        clearInterval(thisWeeksLessonValidationInterval);
      }
    };
  }, [currentUser, lessonsLoading, userLessonTimeValidation]);

  //Update current status on realtime DB to reflect dashboard navigation
  useEffect(() => {
    const date = new Date();
    const lessonStatus = {
      currentChallengeNumber: "",
      currentChallengeTitle: "Dashboard",
      lastChanged: date,
      presence: { online: true, lastChanged: date },
    };
    if (currentUser?.id) {
      setCurrentChallengeStatus({
        currentUser,
        lessonStatus,
      }).catch(console.error);
    }
  }, [currentUser]);

  // If both previous and current lessons are loaded and there is no selected lesson group
  //Select the highest checkpoint number for the first track available.
  useEffect(() => {
    if (!lessonsLoading && !trackCheckpointSelection.track) {
      setTrackCheckpointSelection(defaultTrackAndCheckpoint);
    }
  }, [lessonsLoading]);

  const handleWatchVideoClick = useCallback((lesson, challengeType) => {
    const livestreamChallenge =
      lesson?.challenges?.find(
        (challenge) => challenge?.challengeType === challengeType
      ) || [];

    if (livestreamChallenge) {
      const livestreamWithRenderArr = [
        {
          ...livestreamChallenge,
          lessonId: lesson.lessonId,
          renderArr: [
            ...(livestreamChallenge?.instructions || []),
            ...(livestreamChallenge?.hints || []),
            ...(livestreamChallenge?.example || []),
            ...(livestreamChallenge?.explanation || []),
          ],
        },
      ];

      setLivestream(livestreamWithRenderArr);
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>RocketHour LaunchPad</title>
      </Helmet>
      <Header
        logo
        color={"primary"}
        brand={<DashboardNameDisplay currentUser={currentUser} />}
        rightLinks={<HeaderLinks />}
        bottomRightLinks={
          currentUser?.admin ? (
            <Suspense fallback={<div></div>}>
              <SubHeaderLinks />
            </Suspense>
          ) : null
        }
        fixed
      />
      <Suspense fallback={<LoadingScreen />}>
        <MediaModal modal={livestream} setModal={setLivestream}>
          {livestream &&
            livestream.map((challenge, i) => (
              <ChallengeDataRenderer
                key={`livestreamchallenge${i}`}
                listArray={challenge.renderArr}
                challengeNumber={challenge.challengeNumber}
                lessonIdProp={challenge.lessonId}
                autoPlayVideo
              />
            ))}
        </MediaModal>

        {anyError ? (
          <Box display="flex" justifyContent="center" padding="2rem">
            <ErrorCard onRetry={fetchLessonsAndGroupsAsyncStart}>
              {(Object.values(anyError.messages) || [])
                .filter((value) => value)
                .map((value, i) => (
                  <span key={i}>{value}</span>
                ))}
            </ErrorCard>
          </Box>
        ) : allLoading ? (
          <LoadingScreen />
        ) : (
          <GridContainer className={dashboardClasses.dashboardContainer}>
            {currentUser?.admin && !studentMode && <Alerts {...{ alerts }} />}
            {(currentNews || currentUser?.admin) && (
              <GridItem lg={3} style={{ padding: 0, paddingTop: "30px" }}>
                <GridContainer
                  style={{
                    width: "100%",
                    margin: 0,
                    gap: "0 2rem",
                    justifyContent: "space-between",
                  }}
                >
                  {currentUser?.admin && currentGroup && (
                    <GridItem sm={5} lg={12} style={{ padding: 0 }}>
                      <TutorDashboardInfoComponent />
                    </GridItem>
                  )}
                  {currentNews && (
                    <GridItem
                      lg={12}
                      sm={currentUser?.admin ? 6 : 12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <NewsFeedCard />
                    </GridItem>
                  )}
                  {currentUser?.admin && currentTutorInfo && (
                    <GridItem sm={5} lg={12} style={{ padding: 0 }}>
                      <TutorInfoCard />
                    </GridItem>
                  )}
                </GridContainer>
              </GridItem>
            )}

            <GridItem lg={8} style={{ padding: 0 }}>
              <GridContainer
                style={{
                  width: "100%",
                  justifyContent: "center",
                  margin: "0",
                  alignItems: "center",
                }}
              >
                {currentLesson && (
                  <GridItem
                    className={classes.container}
                    xs={12}
                    style={{ margin: 0, padding: 0 }}
                  >
                    <CurrentLessonCard
                      {...{
                        userLessonTimeValidation,
                        handleWatchVideoClick,
                      }}
                    />
                  </GridItem>
                )}
                <GridItem
                  className={classes.container}
                  xs={12}
                  style={{ margin: 0, padding: 0 }}
                >
                  <PreviousLessonsCard
                    {...{
                      userLessonTimeValidation,
                      handleWatchVideoClick,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        )}
      </Suspense>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentGroup: selectCurrentGroup,
  studentMode: selectStudentMode,
  lessonsLoading: selectLessonsLoading,
  groupsIdNameMap: selectGroupsIdNameMap,
  thisWeeksLessonPresent: selectThisWeeksLessonPresent,
  trackCheckpointSelection: selectTrackCheckpointSelection,
  defaultTrackAndCheckpoint: selectDefaultTrackAndCheckpoint,
  currentLesson: selectCurrentLesson,
  tutorFeedbackDashboard: selectTutorFeedbackDashboard,
  tutorSchedule: selectTutorSchedule,
  currentNews: selectNews,
  currentTutorInfo: selectTutorInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setTrackCheckpointSelection: (selectionObj) =>
    dispatch(setTrackCheckpointSelection(selectionObj)),
  fetchUserLessonDataAsyncStart: (user) =>
    dispatch(fetchUserLessonDataAsyncStart(user)),
  fetchLessonsAndGroupsAsyncStart: () =>
    dispatch(fetchLessonsAndGroupsAsyncStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
