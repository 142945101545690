import { cardTitle } from "../../assets/jss/material-kit-react.js";
import { default as checkboxStyles } from "../../assets/jss/material-kit-react/customCheckboxRadioSwitch.js";

export const ChallengeDataRendererStyles = {
  cardTitle,
  paper: {
    margin: "100px",
    padding: "50px",
    zIndex: "100",
  },
  ...checkboxStyles,
  labelExtra: {
    color: "black",
  },
  labelClick: {
    textDecoration: "line-through",
  },
  nonCheckBox: {
    color: "black",
    cursor: "auto",
    marginLeft: "10px",
  },
  assetContainer: {
    margin: "10px",
    display: "flex",
    justifyContent: "center",
  },
  asset: {
    width: "100%",
    maxHeight: "400px",
    objectFit: "contain",
  },
  mdContainer: {
    display: "flex",
    justifyContent: "center",
  },
  imageAsset: {
    maxHeight: "400px",
    maxWidth: "100%",
    objectFit: "scale-down",
  },
  table: {
    border: "1px solid #555",
    borderCollapse: "collapse",
    padding: "8px",
  },
  tr: {
    border: "1px solid #555",
    padding: "8px",
  },
  td: {
    border: "1px solid #555",
    padding: "8px",
  },
  th: {
    border: "1px solid #555",
    padding: "8px",
  },
  bq: {
    backgroundColor: "#999",
    borderRadius: "5px",
    padding: "0px 0px 0px 0px",
    margin: 0,
    width: "100%",
    // paddingLeft: "3em",
    // borderLeft: "0.5em #eee solid",
  },
  bqp: {
    whiteSpace: "pre-line",
  },
  iframeContainer: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    paddingTop: "calc(75% + 3rem)",
  },
  responsiveIframe: {
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    width: "100%",
    height: "100%",
  },
  reactMarkdown: {
    "& *": {
      margin: "0",
    },
    "& blockquote": {
      backgroundColor: "#F2F2F2",
      border: "1px solid #F2F2F2",
      padding: "3px 5px 3px 5px",
      margin: "3px 5px 3px 5px",
      borderRadius: "5px",
    },
    "& pre": {
      backgroundColor: "#F2F2F2",
      border: "1px solid #F2F2F2",
      padding: "3px 5px 3px 5px",
      margin: "3px 5px 3px 5px",
      borderRadius: "5px",
    },
    "& code": {
      backgroundColor: "#F2F2F2",
      border: "1px solid #F2F2F2",
      padding: "3px 5px 3px 5px",
      margin: "3px 5px 3px 5px",
      borderRadius: "5px",
    },
    "& blockquote > blockquote": {
      paddingLeft: "20px",
    },
    "& ul": {
      paddingLeft: "20px",
    },
    "& ol": {
      paddingLeft: "20px",
    },
    "& hr": {
      width: "100%",
    },
    "& table": {
      border: "1px solid #555",
      borderCollapse: "collapse",
      padding: "8px",
    },
    "& tr": {
      border: "1px solid #555",
      padding: "8px",
    },
    "& td": {
      border: "1px solid #555",
      padding: "8px",
    },
    "& th": {
      border: "1px solid #555",
      padding: "8px",
    },
    "& blockquote > p": {
      whiteSpace: "pre-line",
    },
  },
  screenshotViewerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    width: "100%",
    height: "450px",
  },
  screenshotContentContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 50px",
    position: "relative",
  },
  screenshotImageContainer: {
    width: "100%",
    height: "400px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    position: "relative",
    userSelect: "none",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
  },
  screenshotImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    display: "block",
    userSelect: "none",
    pointerEvents: "none",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
  },
  screenshotNavButton: {
    position: "absolute",
    zIndex: 2,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    margin: "0 10px",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.9)",
    },
  },
  screenshotNavButtonLeft: {
    left: 0,
  },
  screenshotNavButtonRight: {
    right: 0,
  },
  screenshotPaginationBelow: {
    position: "absolute",
    bottom: "-5px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    padding: "2px 8px",
    borderRadius: "10px",
    fontSize: "14px",
    zIndex: 2,
    userSelect: "none",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
  },
  screenshotErrorContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f8f8f8",
    border: "1px dashed #ccc",
    borderRadius: "4px",
    padding: "20px",
    textAlign: "center",
  },
  screenshotErrorUrl: {
    fontSize: "12px",
    color: "#999",
    wordBreak: "break-all",
    maxWidth: "100%",
    marginTop: "10px",
  },
};
