// Modules
import React, { Suspense, useState } from "react";
import { Helmet } from "react-helmet";

// Material UI
import { Box, makeStyles } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { Tooltip } from "@material-ui/core";
import { Chat, Image, InfoOutlined } from "@material-ui/icons";

// Components
import Card from "../../material-kit-pro-components/Card/Card.js";
import CardBody from "../../material-kit-pro-components/Card/CardBody.js";
import CardHeader from "../../material-kit-pro-components/Card/CardHeader.js";
import GridContainer from "../../material-kit-components/Grid/GridContainer";
import GridItem from "../../material-kit-components/Grid/GridItem";
import Button from "../../material-kit-pro-components/CustomButtons/Button.js";
import ChallengeDataRenderer from "../ChallengeDataRenderer/ChallengeDataRenderer.component.jsx";
import CustomTabCard from "../CustomTabCard/CustomTabCard.component.jsx";
import HintsModal from "./Components/HintsModal.component.jsx";
import InTextHintsModal from "./Components/InTextHintsModal.component.jsx";

// Assets and styles
import { default as checkboxStyles } from "../../assets/jss/material-kit-react/customCheckboxRadioSwitch.js";
import { cardTitle } from "../../assets/jss/material-kit-react.js";
import styles from "assets/jss/material-kit-react/popoverStyles.js";
import { whiteColor } from "../../assets/jss/material-kit-pro-react.js";
import ScreenshotViewer from "components/ChallengeDataRenderer/components/ScreenshotViewer.component.jsx";

// Code split for tutor functionality
const ChallengePaginator = React.lazy(() =>
  import("./Components/ChallengePaginator.component")
);

const customStyles = {
  ...styles,
  cardTitle,
  justifyContentCenter: {
    display: "flex",
    justifyContent: "center",
  },
  ...checkboxStyles,
  labelExtra: {
    color: "black",
  },
  labelClick: {
    textDecoration: "line-through",
  },
};

const useStyles = makeStyles(customStyles);

const LessonChallengeCard = ({
  challenge,
  setInstructionsChecked,
  completedChallengesArr,
  setCompletedChallengesArr,
  currentIndex,
  setCurrentIndex,
  challenges,
  adminMode,
  lessonId,
}) => {
  const [showHints, setShowHints] = useState(false);
  const [inTextHints, setInTextHints] = useState({
    show: false,
    hintArray: "",
  });

  const classes = useStyles();

  const hintsPresent = Boolean(challenge?.hints?.length);
  const examplePresent = Boolean(challenge?.example?.length);
  const explanationPresent = Boolean(challenge?.explanation?.length);
  const tutorNotePresent = Boolean(challenge?.tutorNote?.length);
  const screenshotViewerPresent = Boolean(challenge?.screenshotViewer?.length);

  const exampleTab = examplePresent
    ? [
        {
          label: challenge?.exampleTitle ? challenge.exampleTitle : "Example",
          icon: (
            <InfoOutlined style={{ fontSize: "24px", marginBottom: "1px" }} />
          ),
          content: (
            <ChallengeDataRenderer
              listArray={challenge.example}
              challengeNumber={challenge.challengeNumber}
              challengeId={challenge.id}
              lessonIdProp={lessonId}
            />
          ),
        },
      ]
    : [];
  const explanationTab = explanationPresent
    ? [
        {
          label: "Explanation",
          icon: <Chat style={{ fontSize: "24px", marginBottom: "1px" }} />,
          content: (
            <ChallengeDataRenderer
              listArray={challenge.explanation}
              challengeNumber={challenge.challengeNumber}
              challengeId={challenge.id}
              lessonIdProp={lessonId}
            />
          ),
        },
      ]
    : [];
  const tutorNoteTab =
    tutorNotePresent && adminMode
      ? [
          {
            label: "Tutor Note",
            icon: <Chat style={{ fontSize: "24px", marginBottom: "1px" }} />,
            content: (
              <ChallengeDataRenderer
                listArray={challenge.tutorNote}
                challengeNumber={challenge.challengeNumber}
                challengeId={challenge.id}
                lessonIdProp={lessonId}
              />
            ),
          },
        ]
      : [];
  const screenshotViewerTab = screenshotViewerPresent
    ? [
        {
          label: "Screenshots",
          icon: <Image style={{ fontSize: "24px", marginBottom: "1px" }} />,
          content: (
            <ScreenshotViewer challenge={challenge} lessonId={lessonId} />
          ),
        },
      ]
    : [];
  const navTabs = [
    ...exampleTab,
    ...explanationTab,
    ...tutorNoteTab,
    ...screenshotViewerTab,
  ];
  return (
    <div>
      <Helmet>
        <title>
          {challenge.challengeNumber}-{challenge.title}
        </title>
      </Helmet>
      <HintsModal {...{ showHints, setShowHints, challenge, lessonId }} />
      <InTextHintsModal
        {...{
          inTextHints,
          setInTextHints,
          challenge,
          lessonId,
        }}
      />
      <Card
        style={{ margin: "1.5rem 0 0 0", minHeight: "calc(100vh - 12rem)" }}
      >
        <CardHeader color="primary" plain style={{ padding: "5px 15px" }}>
          <Box display="flex" alignItems="center">
            <h4>
              <strong>{challenge.title}</strong>
            </h4>
            <span style={{ margin: "0 1rem" }}>|</span>
            <h4>{challenge.subtitle}</h4>
          </Box>
        </CardHeader>
        <CardBody style={{ paddingBottom: 0 }}>
          {adminMode && (
            <Suspense fallback={<div></div>}>
              <ChallengePaginator
                {...{ currentIndex, setCurrentIndex, challenges }}
              />
            </Suspense>
          )}
          <GridContainer style={{ justifyContent: "center" }}>
            {Boolean(challenge.instructions.length) && (
              <GridItem lg={navTabs.length > 0 ? 5 : 9}>
                <Card>
                  <CardHeader
                    color="primary"
                    plain
                    style={{
                      color: whiteColor,
                      minHeight: "3.5rem",
                      display: "flex",
                      alignItems: "center",
                      boxShadow: "none",
                      padding: "0px 16px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Instructions</span>
                      {hintsPresent && (
                        <Tooltip
                          id="tooltip-left"
                          title="Click to show hints"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <Button
                            onClick={() => setShowHints(true)}
                            color="hint"
                            size="sm"
                            style={{ padding: "8px 16px", margin: 0 }}
                          >
                            <HelpIcon style={{ fontSize: 50 }} />
                            Hint
                          </Button>
                        </Tooltip>
                      )}
                    </div>
                  </CardHeader>
                  <CardBody>
                    <ChallengeDataRenderer
                      checkbox
                      listArray={challenge.instructions}
                      challengeNumber={challenge.challengeNumber}
                      challengeId={challenge.id}
                      lessonIdProp={lessonId}
                      {...{
                        setInstructionsChecked,
                        setInTextHints,
                        setCompletedChallengesArr,
                        completedChallengesArr,
                      }}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            )}

            {navTabs.length > 0 && (
              <GridItem lg={7}>
                <CustomTabCard tabs={navTabs} />
              </GridItem>
            )}
          </GridContainer>
        </CardBody>
      </Card>
    </div>
  );
};

export default LessonChallengeCard;
