import { React, useState, useEffect } from "react";

import GifCanvasAnimatorContainer from "../../GifCanvasAnimator/GifCanvasAnimatorContainer.component";
import { Fade, IconButton, makeStyles } from "@material-ui/core";
import { s3BucketUrlForChallengeAssets } from "utils/firebase/firebase-utils";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { ChallengeDataRendererStyles } from "../ChallengeDataRenderer.styles";

const useStyles = makeStyles(ChallengeDataRendererStyles);

const ScreenshotViewer = ({ challenge, lessonId }) => {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [preloadedImages, setPreloadedImages] = useState([]);
  const [imageErrors, setImageErrors] = useState({});
  const lessonNameSplit = lessonId?.split(".");
  const lessonNameWithPaddedZero =
    lessonNameSplit[0] + "." + lessonNameSplit[1].padStart(2, "0");

  const urlExtensionForAssets =
    s3BucketUrlForChallengeAssets +
    lessonNameWithPaddedZero.toLowerCase().split("l").join("/") +
    "/";

  useEffect(() => {
    if (!challenge?.screenshotViewer) {
      setPreloadedImages([]);
      return;
    }

    const processedImages = challenge.screenshotViewer
      .map((item) => {
        if (!item) return null;
        const imageName = challenge.challengeNumber + "-" + item.trim();

        const imageUrl = urlExtensionForAssets + imageName;
        const imageType = imageName.includes(".gif") ? "gif" : "image";
        const isLoop = item.toLowerCase().includes("loopimage:");
        const isLongImage = item.toLowerCase().includes("longimage:");

        return { imageUrl, imageType, isLoop, isLongImage, originalItem: item };
      })
      .filter(Boolean);

    setPreloadedImages(processedImages);
    setImageErrors({});
    // Reset currentIndex when images change
    setCurrentIndex(0);
  }, [challenge, urlExtensionForAssets]);

  const handleNext = () => {
    if (currentIndex < preloadedImages.length - 1) {
      setLoaded(false);
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setLoaded(false);
      setCurrentIndex(currentIndex - 1);
    }
  };

  useEffect(() => {
    if (preloadedImages.length > 0) {
      preloadedImages.forEach(({ imageUrl, imageType }, index) => {
        if (imageType === "image") {
          const img = new Image();

          img.onerror = () => {
            setImageErrors((prev) => ({
              ...prev,
              [index]: true,
            }));
          };

          img.src = imageUrl;
        }
      });
    }
  }, [preloadedImages]);

  const handleImageError = () => {
    setImageErrors((prev) => ({
      ...prev,
      [currentIndex]: true,
    }));
    setLoaded(true);
  };

  useEffect(() => {
    console.log("ScreenshotViewer mounted/updated with:", {
      challenge,
      lessonId,
      hasScreenshotViewer: Boolean(challenge?.screenshotViewer),
      screenshotViewerLength: challenge?.screenshotViewer?.length,
    });

    return () => {
      console.log("ScreenshotViewer unmounting");
    };
  }, [challenge, lessonId]);

  if (!preloadedImages.length || !preloadedImages[currentIndex]) {
    return <div className={classes.screenshotImageContainer}>Loading...</div>;
  }

  const currentImage = preloadedImages[currentIndex];
  const hasError = imageErrors[currentIndex];

  const isFirstImage = currentIndex === 0;
  const isLastImage = currentIndex === preloadedImages.length - 1;

  return (
    <div className={classes.screenshotViewerContainer}>
      <IconButton
        onClick={handlePrevious}
        className={`${classes.screenshotNavButton} ${classes.screenshotNavButtonLeft}`}
        disabled={isFirstImage || preloadedImages.length <= 1}
        disableRipple={isFirstImage || preloadedImages.length <= 1}
      >
        <ArrowBackIosIcon />
      </IconButton>

      <div className={classes.screenshotContentContainer}>
        <div className={classes.screenshotImageContainer}>
          {currentImage.imageType === "gif" ? (
            hasError ? (
              <div className={classes.screenshotErrorContainer}>
                <p>Unable to load GIF</p>
                <p className={classes.screenshotErrorUrl}>
                  {currentImage.imageUrl}
                </p>
              </div>
            ) : (
              <GifCanvasAnimatorContainer
                className={classes.screenshotImage}
                src={currentImage.imageUrl}
                loop={currentImage.isLoop}
                frameAnimated={!currentImage.isLongImage}
                onError={handleImageError}
              />
            )
          ) : (
            <Fade in={loaded || hasError}>
              {hasError ? (
                <div className={classes.screenshotErrorContainer}>
                  <p>Image not found</p>
                  <p className={classes.screenshotErrorUrl}>
                    {currentImage.imageUrl}
                  </p>
                </div>
              ) : (
                <img
                  className={classes.screenshotImage}
                  src={currentImage.imageUrl}
                  onLoad={() => setLoaded(true)}
                  onError={handleImageError}
                  alt={`Screenshot ${currentIndex + 1}`}
                  style={{ pointerEvents: "auto" }}
                />
              )}
            </Fade>
          )}
        </div>

        {preloadedImages.length >= 1 && (
          <Fade in={true}>
            <div className={classes.screenshotPaginationBelow}>
              {currentIndex + 1} / {preloadedImages.length}
            </div>
          </Fade>
        )}
      </div>

      <IconButton
        onClick={handleNext}
        className={`${classes.screenshotNavButton} ${classes.screenshotNavButtonRight}`}
        disabled={isLastImage || preloadedImages.length <= 1}
        disableRipple={isLastImage || preloadedImages.length <= 1}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </div>
  );
};

export default ScreenshotViewer;
