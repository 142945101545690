// Moduels
import React, { useState } from "react";
import ReactPlayer from "react-player";

// Components
import CustomDropdown from "../../../material-kit-components/CustomDropdown/CustomDropdown";
import Button from "../../../material-kit-components/CustomButtons/Button.js";

const DataRenderedVideo = ({
  item,
  challengeNumber,
  urlExtensionForAssets,
  autoPlayVideo,
  isExplicitVideoUrl,
}) => {
  const [videoPlaybackSpeed, setVideoPlaybackSpeed] = useState(1);

  let videoUrl;

  if (isExplicitVideoUrl) {
    videoUrl = item;
  } else {
    const stringSplit = item.split(":");
    const videoName =
      challengeNumber +
      "-" +
      stringSplit
        .filter((item, i) => i >= 1)
        .join(":")
        .trim();
    videoUrl = urlExtensionForAssets + videoName;
  }

  const handleSpeedChange = (e) => {
    const speed = Number(e.target.innerText.split("X")[0]);
    setVideoPlaybackSpeed(speed);
  };

  return (
    <div>
      <ReactPlayer
        width="100%"
        height="100%"
        controls
        url={videoUrl}
        playbackRate={videoPlaybackSpeed}
        playing={autoPlayVideo}
      />
      <CustomDropdown
        dropup
        dropdownHeader="Speed"
        buttonText="Playback Speed"
        buttonProps={{
          round: true,
          color: "primary",
          size: "sm",
        }}
        noLiPadding
        dropdownList={[
          <Button
            simple
            color="transparent"
            style={{
              padding: "10px",
              margin: "0",
              width: "100%",
              height: "100%",
              justifyContent: "start",
            }}
            onClick={handleSpeedChange}
            key="1.0"
          >
            1.0x
          </Button>,
          <Button
            simple
            color="transparent"
            style={{
              padding: "10px",
              margin: "0",
              width: "100%",
              height: "100%",
              justifyContent: "start",
            }}
            onClick={handleSpeedChange}
            key="1.25"
          >
            1.25x
          </Button>,
          <Button
            simple
            color="transparent"
            style={{
              padding: "10px",
              margin: "0",
              width: "100%",
              height: "100%",
              justifyContent: "start",
            }}
            onClick={handleSpeedChange}
            key="1.5"
          >
            1.5x
          </Button>,
          <Button
            simple
            color="transparent"
            style={{
              padding: "10px",
              margin: "0",
              width: "100%",
              height: "100%",
              justifyContent: "start",
            }}
            onClick={handleSpeedChange}
            key="1.75"
          >
            1.75x
          </Button>,
          <Button
            simple
            color="transparent"
            style={{
              padding: "10px",
              margin: "0",
              width: "100%",
              height: "100%",
              justifyContent: "start",
            }}
            onClick={handleSpeedChange}
            key="2.0"
          >
            2.0x
          </Button>,
        ]}
      />
    </div>
  );
};

export default DataRenderedVideo;
