export const TutorInfoCardStyles = {
  assetContainer: {
    margin: "10px",
    display: "flex",
    justifyContent: "center",
  },
  imageAsset: {
    objectFit: "cover",
    width: "100%",
  },
  asset: {
    width: "100%",
    maxHeight: "400px",
    objectFit: "contain",
  },
  iframeContainer: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    paddingTop: "calc(75% + 3rem)",
  },
  responsiveIframe: {
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    width: "100%",
    height: "100%",
  },
  reactMarkdown: {
    "& *": {
      margin: "0",
    },
    "& blockquote ,& code": {
      backgroundColor: "#F2F2F2",
      border: "1px solid #F2F2F2",
      padding: "3px 5px 3px 5px",
      borderRadius: "5px",
    },
  },
};
