// Utilities
import { groupsTableFieldNames } from "../../utils/airtable/ids-and-fieldnames/airtable-groups-table-fieldnames.utils";
import { getCurrentNewsItem } from "../../utils/firebase/firestore-utils";
import { selectCurrentUser } from "../user/user.selectors";

// Redux types
import { NewsActionTypes } from "./news.types";

export const fetchNewsStart = () => ({
  type: NewsActionTypes.FETCH_NEWS_START,
  payload: undefined,
});

export const fetchNewsSuccess = (newsObject) => ({
  type: NewsActionTypes.FETCH_NEWS_SUCCESS,
  payload: newsObject,
});

export const fetchNewsFailure = (errorMessage) => ({
  type: NewsActionTypes.FETCH_NEWS_FAILURE,
  payload: errorMessage,
});

export const fetchNewsStartAsync = (group) => {
  return (dispatch, getState) => {
    // To be inserted again once we implement news
    // return null;
    const state = getState();
    const currentUser = selectCurrentUser(state);

    let currentCohort;

    if (currentUser?.admin) {
      // If current user is admin use the selected current group's cohort
      // as opposed to the cohort stored on the current user profile
      currentCohort = group?.[groupsTableFieldNames.cohort_name_string_co]?.[0];
    } else if (currentUser?.cohort) {
      // Use cohort from currentUser object
      currentCohort = currentUser.cohort[0];
    }

    if (currentCohort) {
      dispatch(fetchNewsStart());
      getCurrentNewsItem(currentCohort)
        .then((res) => {
          const newsItem = res?.newsDoc ? res.newsDoc : null;
          const tutorItem = res?.tutorInfoDoc ? res.tutorInfoDoc : null;
          dispatch(fetchNewsSuccess({ newsItem, tutorItem }));
        })
        .catch((err) => {
          console.error(err);
          dispatch(fetchNewsFailure(err.message));
        });
    }
  };
};
