import { NewsActionTypes } from "./news.types";

const INITIAL_STATE = {
  loading: false,
  news: null,
  errorMessage: undefined,
};

const newsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NewsActionTypes.FETCH_NEWS_START:
      return {
        ...INITIAL_STATE,
        loading: true,
      };
    case NewsActionTypes.FETCH_NEWS_SUCCESS:
      return {
        ...INITIAL_STATE,
        news: action.payload.newsItem,
        tutorInfo: action.payload.tutorItem,
      };
    case NewsActionTypes.FETCH_NEWS_FAILURE:
      return {
        ...INITIAL_STATE,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default newsReducer;
