// Firebase
import firebase from "firebase/compat/app";
import { firestore } from "./firebase-utils";

export const getUserRef = (userAuth) => {
  if (!userAuth) return;
  const userRef = firestore.doc(`users/${userAuth.uid}`);

  return userRef;
};

export const getCurrentLessons = async (cohortArr) => {
  let tempCohorArr = [...cohortArr];
  let batches = [];

  while (tempCohorArr.length) {
    const batch = tempCohorArr.splice(0, 10);
    batches.push(batch);
  }

  const currentLessonDocsObj = {};

  const allSnapshotQueries = await Promise.all(
    batches.map((batch) => {
      return firestore
        .collection("lessons")
        .where("cohortLessonMatchingCurrent", "array-contains-any", batch)
        .get();
    })
  );

  allSnapshotQueries.forEach((querySnapshot) => {
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        const lesson = { ...doc.data() };
        currentLessonDocsObj[lesson.id] = lesson;
      });
    }
  });

  return currentLessonDocsObj;
};

export const getSingleLesson = async (lessonId) => {
  let returnObject;
  const currentLessonDocsObj = await firestore
    .collection("lessons")
    .where("id", "==", lessonId)
    .get();

  if (!currentLessonDocsObj.empty) {
    currentLessonDocsObj.forEach((doc) => {
      const lesson = { ...doc.data() };
      returnObject = lesson;
    });
  }

  // allSnapshotQueries.forEach((querySnapshot) => {
  //   if (!querySnapshot.empty) {
  //     querySnapshot.forEach((doc) => {
  //       const lesson = { ...doc.data() };
  //       currentLessonDocsObj = lesson;
  //     });
  //   }
  // });

  return returnObject;
};

export const getPreviousLessons = async (cohortArr) => {
  let tempCohorArr = [...cohortArr];
  let batches = [];

  while (tempCohorArr.length) {
    const batch = tempCohorArr.splice(0, 10);
    batches.push(batch);
  }

  const lessonDocsObj = {};

  const allSnapshotQueries = await Promise.all(
    batches.map((batch) => {
      return firestore
        .collection("lessons")
        .where("cohortLessonMatchingAll", "array-contains-any", batch)
        .get();
    })
  );

  allSnapshotQueries.forEach((querySnapshot) => {
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        const lesson = { ...doc.data() };
        lessonDocsObj[lesson.id] = lesson;
      });
    }
  });

  return lessonDocsObj;
};
export const getLessonsByIdArr = async (lessonIdArr) => {
  let tempCohorArr = [...lessonIdArr];
  let batches = [];

  while (tempCohorArr.length) {
    const batch = tempCohorArr.splice(0, 10);
    batches.push(batch);
  }

  const lessonDocsObj = {};

  const allSnapshotQueries = await Promise.all(
    batches.map((batch) => {
      return firestore.collection("lessons").where("id", "in", batch).get();
    })
  );

  allSnapshotQueries.forEach((querySnapshot) => {
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        const lesson = { ...doc.data() };
        lessonDocsObj[lesson.id] = lesson;
      });
    }
  });

  return lessonDocsObj;
};

export const setUserLessonProgressDoc = async (user, lesson, challengeId) => {
  if (!user || !lesson?.id || !challengeId) return;
  const userLesson = firestore.doc(`users/${user.id}/lessons/${lesson.id}`);
  const { id, lessonId } = lesson;
  return await userLesson.set(
    {
      id,
      lessonId,
      completedChallenges:
        firebase.firestore.FieldValue.arrayUnion(challengeId),
    },
    { merge: true }
  );
};

export const getUserLessonDocs = async (user) => {
  const userLessonData = firestore.collection(`users/${user.id}/lessons`);
  const collection = {};
  const lessonDataCollection = await userLessonData.get();

  lessonDataCollection.forEach((doc) => {
    collection[doc.id] = doc.data();
  });

  return collection;
};

export const getFeedbackDocRef = (feedbackId) => {
  if (!feedbackId) return;
  const feedbackRef = firestore.doc(`feedback/${feedbackId}`);

  return feedbackRef;
};

export const setFeedbackDoc = async (feedbackId, feedbackObj, handleError) => {
  const feedbackRef = getFeedbackDocRef(feedbackId);

  return await feedbackRef.set(feedbackObj, { merge: true }, (error) => {
    handleError(error);
  });
};

export const getStudentFeedbackDocRef = (feedbackId, studentUserName) => {
  if (!feedbackId) return;
  const feedbackRef = firestore.doc(
    `feedback/${feedbackId}/students/${feedbackId}-${studentUserName}`
  );

  return feedbackRef;
};

export const setStudentFeedbackDoc = async (
  feedbackId,
  studentUserName,
  feedbackObj
) => {
  const feedbackRef = getStudentFeedbackDocRef(feedbackId, studentUserName);

  return await feedbackRef.set(feedbackObj, { merge: true });
};

const getFirstDayOfWeek = () => {
  // 👇️ clone date object, so we don't mutate it
  const date = new Date();
  const day = date.getDay(); // 👉️ get day of week

  // 👇️ day of month - day of week (-6 if Sunday), otherwise +1
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  const firstDay = new Date(date.setDate(diff));
  firstDay.setHours(0, 0, 0);
  return firstDay;
};

export const getThisWeeksUnsubmittedFeedback = async (groups) => {
  const startOfTheWeek = getFirstDayOfWeek();
  if (groups.length) {
    let tempgroupsArr = [...groups];
    let batches = [];

    while (tempgroupsArr.length) {
      const batch = tempgroupsArr.splice(0, 10);
      batches.push(batch);
    }

    const unsubmittedFeedbackArr = [];

    for (let batch of batches) {
      const query = await firestore
        .collection("feedback")
        .where("dataTimestamp", ">", startOfTheWeek)
        .where("group", "in", batch)
        .where("pendingSubmission", "==", true)
        .get();

      query.forEach((doc) => unsubmittedFeedbackArr.push(doc.data()));
    }

    return unsubmittedFeedbackArr;
  } else {
    return [];
  }
};

export const getCurrentNewsItem = async (cohort) => {
  const newsDocsQuery = await firestore
    .collection("news")
    .where("cohorts", "array-contains", cohort)
    .where("type", "==", "news")
    .limit(1)
    .get();

  const tutorInfoQuery = await firestore
    .collection("news")
    .where("cohorts", "array-contains", cohort)
    .where("type", "==", "tutor_info")
    .limit(1)
    .get();

  let newsDoc;
  let tutorInfoDoc;

  if (!newsDocsQuery.empty) {
    newsDocsQuery.forEach((doc) => (newsDoc = doc.data()));
  }

  if (!tutorInfoQuery.empty) {
    tutorInfoQuery.forEach((doc) => (tutorInfoDoc = doc.data()));
  }

  console.log({ newsDoc, tutorInfoDoc });
  return { newsDoc, tutorInfoDoc };
};

// Sets the value of a report doc
export const setReportDoc = async (reportDoc) => {
  if (!reportDoc) return;
  const userLesson = firestore.doc(`reports/${reportDoc.id}`);

  // Protection in case checkedItems gets overwritten in set call
  // checkedItems should be manipulated only by addReportCheckedItem and removeReportCheckedItem
  const reportDocValue = { ...reportDoc };
  delete reportDocValue.checkedItems;

  return await userLesson.set(reportDocValue, { merge: true });
};

// Adds the record id of a report template checklist item to the array on firestore
export const addReportCheckedItem = async (reportDoc, checkItemID) => {
  if (!reportDoc || !checkItemID) return;
  const userLesson = firestore.doc(`reports/${reportDoc.id}`);

  return await userLesson.set(
    { checkedItems: firebase.firestore.FieldValue.arrayUnion(checkItemID) },
    { merge: true }
  );
};

// Adds the record id of a report template checklist item from the array on firestore
export const removeReportCheckedItem = async (reportDoc, checkItemID) => {
  if (!reportDoc || !checkItemID) return;
  const userLesson = firestore.doc(`reports/${reportDoc.id}`);

  return await userLesson.set(
    { checkedItems: firebase.firestore.FieldValue.arrayRemove(checkItemID) },
    { merge: true }
  );
};
